import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import { Box, Themed, Button, Grid, Textarea } from "theme-ui";
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout"
import Divider from "@lekoarts/gatsby-theme-cara/src/elements/divider"
import Svg from "@lekoarts/gatsby-theme-cara/src/components/svg"
import Seo from "@lekoarts/gatsby-theme-cara/src/components/seo"
import Content from "@lekoarts/gatsby-theme-cara/src/elements/content"
import Inner from "@lekoarts/gatsby-theme-cara/src/elements/inner"
import { ethers } from "ethers";

import abi from "../utils/WavePortal.json";

const WaveApp = () => {
    const [currentAccount, setCurrentAccount] = useState("");
    const [allWaves, setAllWaves] = useState([]);
    const [message, setMessage] = useState("");

    const handleMessage = (event) => {
	    setMessage(event.target.value);
        console.log("Message handled");
    };
    const handleSubmit = (event) => {
        setMessage("");
        console.log("Message cleared");
    };

    const contractAddress = "0x7a4Dec25F5d0C45db504Ea2658938099a9A3564b";
    /* Create a variable here that references the abi content! */
    const contractABI = abi.abi;

    const checkIfWalletIsConnected = async () => {
        try {
            const { ethereum } = window;

            if (!ethereum) {
                console.log("Make sure you have metamask!");
                return;
            } else {
                console.log("Hello Maia. We have the ethereum object now", ethereum);
            }

            /* Check if we're authorized to access the user's wallet*/
            const accounts = await ethereum.request({ method: 'eth_accounts' });

            if (accounts.length !== 0) {
                const account = accounts[0];
                console.log("Hello Maia. We found an authorized account:", account);
                setCurrentAccount(account)
            } else {
                console.log("No authorized account found")
            }

        } catch (error) {
            console.log(error);
        }
    }

    /* Implement your connectWallet method here */
    const connectWallet = async () => {
        try {
            const { ethereum } = window;
            if (!ethereum) {
                alert("Get MetaMask!");
                return;
            }
            const accounts = await ethereum.request({ method: "eth_requestAccounts" });
            console.log("Hello Maia. We are connected now:", accounts[0]);
            setCurrentAccount(accounts[0]);

        } catch (error) {
            console.log(error)
        }
    }

    const wave = async () => {
        try {
            const { ethereum } = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const wavePortalContract = new ethers.Contract(contractAddress, contractABI, signer);

                let count = await wavePortalContract.getTotalWaves();
                console.log("Current wave count...", count.toNumber());

                /* Execute the actual wave from your smart contract */
                const waveTxn = await wavePortalContract.wave(message)
                console.log("Mining...", waveTxn.hash);

                await waveTxn.wait();
                console.log("Mined -- ", waveTxn.hash);

                count = await wavePortalContract.getTotalWaves();
                console.log("New total wave count...", count.toNumber());
                getAllWaves();
                console.log("Added new wave to log.");
                handleSubmit();

            } else {
                console.log("Ethereum object doesn't exist!");
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getAllWaves = async () => {
        try {
            const { ethereum } = window;
            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const wavePortalContract = new ethers.Contract(contractAddress, contractABI, signer);

                const waves = await wavePortalContract.getAllWaves();

                let wavesCleaned = [];
                waves.forEach(wave => {
                    wavesCleaned.push({
                        address: wave.waver,
                        timestamp: new Date(wave.timestamp * 1000),
                        message: wave.message
                    });
                });

            setAllWaves(wavesCleaned);

            } else {
                console.log("Ethereum object doesn't exist!")
            }
        } catch (error) {
            console.log(error);
        }
    }

    /* This runs our function when the page loads.*/
    useEffect(() => {
       checkIfWalletIsConnected();
       getAllWaves();
    }, [])


    return (
        <Layout>
            <Seo title="Wave | Hello Maia" />
                <Parallax pages={2}>
                <ParallaxLayer
                    offset={0}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Divider speed={0.2} offset={0} factor={3}>
                    <Svg icon="circle" width={6} color="icon_darkest" left="4%" top="20%" />
                    <Svg icon="circle" width={12} color="icon_darkest" left="50%" top="60%" />
                    <Svg icon="triangle" width={8} stroke color="icon_darker" left="25%" top="5%" />
                    <Svg icon="circle" width={64} color="icon_green" left="95%" top="5%" />
                    <Svg icon="box" width={6} color="icon_darkest" left="10%" top="10%" />
                    <Svg icon="hexa" width={16} stroke color="icon_darker" left="10%" top="50%" />
                    <Svg icon="hexa" width={8} stroke color="icon_darker" left="80%" top="70%" />
                  </Divider>
                  <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={0} factor={1}>
                      <Inner>
                          <Themed.h1>Wave 👋</Themed.h1>
                          <Themed.h3><em>Wave at me on the block</em>!</Themed.h3>
                          <Grid gap={3} columns={[2, '2fr 1fr']}>
                              <Box>
                                  <Themed.p>You'll need an Ethereum wallet connected to the Rinkeby Test Network to use this app.</Themed.p>
                                  <Themed.ol>
                                      <Themed.li>
                                          Connect your Ethereum wallet.
                                      </Themed.li>
                                      <Themed.li>
                                          Enter your message.
                                      </Themed.li>
                                      <Themed.li>
                                          Wave at me!
                                      </Themed.li>
                                  </Themed.ol>
                                  <Themed.p>
                                      The process takes about 45 seconds to be mined on the network.
                                      <br/><br/>Once the call is confirmed
                                      your message will appear on the right. <em>Please be patient.</em> This is a work in progress,
                                      as I'm still experimenting.
                                      <br/><br/>Thanks for checking it out! <em><br/>Scroll down 👇 </em>
                                  </Themed.p>
                              </Box>
                          </Grid>
                    </Inner>
                </Content>
                </ParallaxLayer>
                <ParallaxLayer
                    offset={1}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Content sx={{ variant: `texts.bigger` }} speed={0.4} offset={0} factor={1}>
                        <Inner>
                            <Themed.h5><em>Wave.</em> Don't be shy, now ;)</Themed.h5>
                            <Grid gap={3} columns={[2, '1fr 2fr']} mt={4}>
                                <Box>
                                    {!currentAccount && (
                                        <Button mr={2} style={{ cursor: "pointer"}} onClick={connectWallet}>
                                            Connect Wallet
                                        </Button>
                                    )}
                                    {currentAccount && (
                                        <Box as="form" onSubmit={(e) => e.preventDefault()}>
                                            <Textarea value={message} onChange={handleMessage}/>
                                            <Button onClick={wave} mt={2} style={{ cursor: "pointer"}}>
                                                Wave at Me
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                                <Box>
                                    <div style={{
                                        height: "50vh",
                                        overflowY: "scroll",
                                        borderRadius: "4px",
                                        backgroundColor:"rgba(0,0,0,0.2)",
                                        padding:"10px 20px"
                                    }}>
                                        {allWaves.reverse().map((wave, index) => (
                                            <div key={index} style={{ padding: "8px" }}>
                                                <div><strong>Address</strong>: {wave.address}</div>
                                                <div><strong>Time</strong>: {wave.timestamp.toString()}</div>
                                                <div><strong>Message</strong>: {wave.message}</div>
                                            </div>
                                            )
                                        )}
                                    </div>
                                </Box>
                            </Grid>
                            <Themed.p>
                                Go back to <Link to="/">homepage</Link>.
                            </Themed.p>
                        </Inner>
                    </Content>
                </ParallaxLayer>
                </Parallax>
      </Layout>
  );
}

export default WaveApp
